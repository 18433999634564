import React, { Component, createRef } from "react"
import i18n from "shared/runners/i18n"
import bowser from "bowser"
import moment from "moment"
import { extend, isEqual, omit, uniqueId } from "lodash"
import { string, func, number, object } from "prop-types"

class DateInput extends Component {
  // Passes almost all props along to the controlled hidden input and
  // controlling text input, with some intelligence about the name,
  // defaultValue, etc. These are the most common
  static propTypes = {
    id: string,
    className: string,
    defaultValue: string,
    name: string,
    onChange: func,
    value: string,
    minDate: string,
    maxDate: string,
    pastYearsToShow: number,
    futureYearsToShow: number,
    style: object,
  }

  static defaultProps = {
    pastYearsToShow: 20,
    futureYearsToShow: 2,
  }

  static useSystemDatepicker = bowser.ios || bowser.android

  state = {
    railsDateValue: this.props.value || this.props.defaultValue,
    generatedId: uniqueId("react-jquery-ui-datepicker"),
    datepickerAttached: false,
    datepickerOptions: this.getJQueryDatepickerOptions(),
  }

  inputRef = createRef()
  hiddenInputRef = createRef()

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.hasOwn(nextProps, "value")) {
      return
    }

    if (nextProps.value !== this.props.value) {
      this.setState({
        railsDateValue: nextProps.value,
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(omit(this.props, "onChange"), omit(nextProps, "onChange")) ||
      !isEqual(this.state, nextState)
    )
  }

  componentDidUpdate() {
    this.updateDatepicker()
  }

  componentWillUnmount() {
    this.detachDatepicker()
  }

  handleDatepickerFocus = () => {
    this.attachDatepicker()
    jQuery(this.inputRef.current).datepicker("show")
  }

  handleDatepickerBlur = (event) => {
    const input = jQuery(this.inputRef.current)
    const { relatedTarget } = event

    if (event.relatedTarget && !input.datepicker("widget").get(0).contains(relatedTarget)) {
      jQuery(this.inputRef.current).datepicker("hide")
    }
  }

  handleJQueryDatepickerChange = (date) => {
    this.setState({
      railsDateValue: moment(date, i18n.dateFormatMoment).format(i18n.dateFormatRails),
    })

    if (this.props.onChange) {
      const fakeEvent = { target: this.hiddenInputRef.current }
      this.props.onChange(fakeEvent)
    }
  }

  handleJQueryDatepickerClose = () => {
    jQuery(this.inputRef.current).focus()
  }

  handleInputClick = () => {
    jQuery(this.inputRef.current).datepicker("show")
  }

  attachDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }
    if (this.state.datepickerAttached) {
      return
    }

    jQuery(this.inputRef.current).datepicker(this.getJQueryDatepickerOptions())
    this.setState({ datepickerAttached: true })
  }

  updateDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }

    const datepickerOptions = this.getJQueryDatepickerOptions()

    if (isEqual(this.state.datepickerOptions, datepickerOptions)) {
      return
    }

    jQuery(this.inputRef.current).datepicker("option", datepickerOptions)
    this.setState({ datepickerOptions })
  }

  detachDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }

    const $input = jQuery(this.inputRef.current)

    $input.datepicker("hide")
    $input.datepicker("destroy")
  }

  getJQueryDatepickerOptions() {
    const options = {
      dateFormat: i18n.dateFormatJQuery,
      changeMonth: true,
      changeYear: true,
      yearRange: `-${this.props.pastYearsToShow}:+${this.props.futureYearsToShow}`,
      onSelect: this.handleJQueryDatepickerChange,
      onClose: this.handleJQueryDatepickerClose,
    }

    if (this.props.minDate) {
      options.minDate = moment(this.props.minDate).toDate()
    }
    if (this.props.maxDate) {
      options.maxDate = moment(this.props.maxDate).toDate()
    }

    return options
  }

  getRailsHiddenInputProps() {
    const consumedProps = Object.keys(DateInput.propTypes)

    return extend(omit(this.props, consumedProps), {
      name: this.props.name,
      readOnly: true,
      ref: this.hiddenInputRef,
      type: "hidden",
      value: this.state.railsDateValue || "",
    })
  }

  getJQueryDatepickerProps() {
    const consumedProps = Object.keys(DateInput.propTypes)
    const value = this.state.railsDateValue
      ? moment(this.state.railsDateValue, i18n.dateFormatRails).format(i18n.dateFormatMoment)
      : ""

    return extend(omit(this.props, consumedProps), {
      className: this.props.className,
      readOnly: true,
      ref: this.inputRef,
      type: "text",
      value,
      id: this.props.id || this.state.generatedId,
    })
  }

  render() {
    if (DateInput.useSystemDatepicker) {
      const systemDatePickerProps = omit(this.props, "className")

      return (
        <input
          type="date"
          className={["giving-datepicker", this.props.className || "w-100%"].join(" ")}
          {...systemDatePickerProps}
        />
      )
    }

    return (
      <span>
        <input
          {...this.getJQueryDatepickerProps()}
          onFocus={this.handleDatepickerFocus}
          onBlur={this.handleDatepickerBlur}
          onClick={this.handleInputClick}
          className="w-100%"
          style={{ ...this.props.style }}
        />
        <input {...this.getRailsHiddenInputProps()} />
      </span>
    )
  }
}

export default DateInput
